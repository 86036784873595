// src/services/jackpotUserService.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Ensure this is set in your .env file
});

const getToken = ()=>{
  const token = localStorage.getItem('bingo_access_token');
  const config = {
      headers:{
          Authorization: `Bearer ${token}`
      }
  };
  return config;
}


// Create a new user
export const createJackpotUser = async (userData) => {
  try {
    const response = await axiosInstance.post('jackpot/jackpotuser', userData,getToken());
    return response.data;
  } catch (error) {
    
    throw error.response.data;
  }
};

// Get all users
export const getJackpotUsers = async () => {
  try {
    const response = await axiosInstance.get('jackpot/jackpotuser',getToken());
    
    return response.data;
  } catch (error) {
    
    throw error.response.data;
  }
};

// Get user by ID
export const getJackpotUserById = async (userId) => {
  try {
    const response = await axiosInstance.get(`jackpot/jackpotuser/${userId}`);
    return response.data;
  } catch (error) {
    
    throw error.response.data;
  }
};
// Get user by phone
export const getJackpotUserByPhone = async (phone) => {
  try {
    const response = await axiosInstance.get(`jackpot/jackpotuser/phone/${phone}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Get user by phone
export const importUserForJackpot = async (phone) => {
    try {
      const response = await axiosInstance.get(`jackpot/jackpotUser/userData/${phone}`,getToken());
      return response.data;
    } catch (error) {
      //console.error('Error fetching JackpotUser by ID:', error.response.data);
      throw error.response.data;
    }
  };

// Get user by role
export const getJackpotUserByRole = async (role) => {
  try {
    const response = await axiosInstance.get(`jackpot/jackpotuser/role/${role}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
// Update a user by ID
export const updateJackpotUser = async (userId, userData) => {
  try {
    const response = await axiosInstance.put(`jackpot/jackpotuser/${userId}`, userData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Delete a user by ID
export const deleteJackpotUser = async (userId) => {
  try {
    const response = await axiosInstance.delete(`jackpot/jackpotuser/${userId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
