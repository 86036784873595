

import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

const getToken = ()=>{
    const token = localStorage.getItem('bingo_access_token');
    const config = {
        headers:{
            Authorization: `Bearer ${token}`
        }
    };
    return config;
}


const create_cartela = async (boards) => {
    try {
        const data = await axiosInstance.post('/boards',boards,getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        throw error.response.data;
    }
}

const get_board_by_id = async (id) =>{
    try{
        const data = await axiosInstance.get('/boards/check/'+id,getToken());
        return data;
    }catch(err){
        checkStatusAndRefreshPage(err);
        throw err.response.data;
    }
}

const get_all_boards = async (selectedBranch) =>{
    try {
        const data = await axiosInstance.get('/boards?selectedBranch='+selectedBranch,getToken());
        return data;
    } catch (err) {
        
        checkStatusAndRefreshPage(err);
        throw err.response.data;
    }
}

const check_winning = async(id,call_list,pattern,patternId1,patternId2,refundPattern,isOrOp) =>{
    try {
        
        //const data = await axiosInstance.get(`/boards/check/${id}/${call_list}/${pattern}`,getToken());
        const reqObj = {id,call_list,pattern,patternId1,patternId2,refundPattern,isOrOp};
        const data = await axiosInstance.post('/boards/check',reqObj,getToken());
        return data;
    } catch (error) {
        console.log(error);
        checkStatusAndRefreshPage(error);
        throw error.response.data;
    }
}
const delete_board_by_id = async(id)=>{
    try {
        const data = await axiosInstance.delete('/boards/'+id,getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        throw error.response.data;
    }
}

//sync_boards
const sync_boards = async(company_id)=>{
    try {
        const data = await axiosInstance.get('/boards/sync/'+company_id,getToken());
        return data;
    } catch (error) {
        console.log(error);
        checkStatusAndRefreshPage(error);
        throw error.response.data;
    }
}


const checkStatusAndRefreshPage = async(error)=>{
    try {
        if(error.response.status === 401){
            window.location.reload();
            localStorage.removeItem('bingo_user_info');
            localStorage.removeItem('bingo_access_token');
        }
    } catch (error) {
        
    }
}

const boardService = {
    create_cartela,
    get_board_by_id,
    check_winning,
    get_all_boards,
    delete_board_by_id,
    sync_boards
};

export default boardService;
