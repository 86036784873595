import React, { useState, useEffect,useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, Grid, Typography, Box, Paper, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';




import img1 from '../../components/bingo/images/1.jpg';
import img2 from '../../components/bingo/images/2.jpg';
import img3 from '../../components/bingo/images/3.jpg';


import './jackpot.css';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Gamepad, List } from '@mui/icons-material';
import PaidIcon from '@mui/icons-material/Paid';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import NumbersIcon from '@mui/icons-material/Numbers';
import BuyCartelaComponent from './buyCartelaComponent';
import GameSelectorComponent from './gameSelectorComponent';
import JackpotTransactionModal from './JackpotTransactionComponent';
import ResultModal from './ResultModal';
import PatternGrid from './patternGrid';


import { useSelector,useDispatch } from 'react-redux';
import { fetchGamesForToday,fetchGameById,reset_purchase_states, getJackpotCreditByPhone,fetchLastPlayedJackpotGame } from 'src/redux/slices/jackpot/jackpotGameplaySlice';


import {
  initiateSocketConnection,
  disconnectSocket,
  subscribeToEvent,
  emitEvent
}
from '../../services/socketManager';

//amharic voice.
import { SelectVoice } from 'src/components/bingo/BingoVoiceSelector';
import { readyPlay } from 'src/components/bingo/chimes';
import JackpotDialog from './jackpotDialog';


//start winner banner
import { keyframes } from '@mui/system';
const marquee = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }  // Changed to -50% to create seamless loop
`;


const WinnerBanner = styled(Box)(({ theme }) => ({
  width: '100%',
  background: 'linear-gradient(180deg, #fff 50%, #fff 100%)',
  padding: theme.spacing(1),
  overflow: 'hidden', // Ensure content doesn't spill out
  position: 'sticky',
  top: 0,
  zIndex: 100,
  marginBottom: 0,
  borderRadius: 0,
  boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
  height: '60px',
  display: 'flex',
  alignItems: 'center'
}));

const MarqueeContent = styled('div')({
  display: 'flex',
  whiteSpace: 'nowrap',
  animation: `${marquee} 30s linear infinite`,
  height: '100%',
  alignItems: 'center',
  '& > span': {
    display: 'inline-flex',
    margin: '0 1rem', // Reduced margin between items
  }
});

const WinnerInfo = styled('span')(({ theme }) => ({
  color: '#000',
  fontWeight: 'bold',
  fontSize: '2.5rem',
  display: 'inline-flex', // Changed to inline-flex
  alignItems: 'center',
  whiteSpace: 'nowrap', // Ensure no wrapping
  '& .highlight': {
    color: '#ff0f00', 
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  '& .amount': {
    color: '#ff00f0',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  }
}));

//end winner banner


const BingoContainer = styled(Box)(({ theme }) => ({ 
  background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)', 
  padding: 0, // Remove padding
  color: '#333', 
  width: '100%',
  height: 'calc(100vh - 48px)', 
  //minHeight: '100vh',
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)', 
  fontFamily: 'ZCOOL QingKe HuangYou', 
  display: 'flex',
  
  
}));

const InfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
  borderRadius: '15px',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '75%',
  borderRadius: '12px',
  fontWeight: 'bold',
  transition: 'all 0.3s ease-in-out',
  
}));

const BallDisplay = styled(motion.div)(({ theme }) => ({
  width: '120px',
  height: '120px',
  borderRadius: '50%',
  background: 'radial-gradient(circle at 30% 30%, #ffffff, #e0e0e0)',
  boxShadow: '4px 4px 10px rgba(0,0,0,0.2), -2px -2px 10px rgba(255,255,255,0.8), inset -2px -2px 10px rgba(0,0,0,0.1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  padding: '10px',

  '&.blue': { background: 'radial-gradient(circle at 30% 30%, #90caf9, #2196f3)' },
  '&.green': { background: 'radial-gradient(circle at 30% 30%, #a5d6a7, #4caf50)' },
  '&.red': { background: 'radial-gradient(circle at 30% 30%, #ef9a9a, #f44336)' },
  '&.yellow': { background: 'radial-gradient(circle at 30% 30%, #fff59d, #ffeb3b)' },
  '&.white': { background: 'radial-gradient(circle at 30% 30%, #ffffff, #e0e0e0)' },
}));

const BallContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
  fontFamily: 'ZCOOL QingKe HuangYou',
  
  '& .ball-letter': {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: '2px',
  },
  
  '& .ball-number': {
    fontSize: '2.2rem',
    fontWeight: 'bold',
  }
});

const getBallDisplay = (ball) => {
  return (
    <motion.div
      className={`jackpot-ball-display ${ball.color}`}
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.1 }}
    >
      <div className="jackpot-content">
        <div className="jackpot-ball-content">
          <div className="jackpot-ball-letter">{ball.letter}</div>
          <div className="jackpot-ball-number">{ball.number}</div>
        </div>
      </div>
    </motion.div>
  );
};


const LastCalledNumber = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  background: 'rgba(248, 248, 248, 0.9)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  gap: theme.spacing(2),
  minWidth: '100%',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #ff4b4b, #ffb74d, #4caf50, #42a5f5, #9c27b0)',
  }
}));



const CallBlock = styled(motion.div)(({ color }) => ({
  width: '50px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 8px',
  borderRadius: '50%',
  backgroundColor: color,
  boxShadow: '0 6px 10px rgba(0, 0, 0, 0.15)',
}));

const LastCalledNumberDisplay = ({ lastFiveCalls }) => {
  const getBallColor = (letter) => {
    switch (letter) {
      case 'B': return '#ff6b6b';
      case 'I': return '#feca57';
      case 'N': return '#48dbfb';
      case 'G': return '#ff9ff3';
      case 'O': return '#54a0ff';
      default: return '#ffffff';
    }
  };

  return (
    <LastCalledNumber elevation={3}>
      <AnimatePresence>
        {lastFiveCalls.map((call, index) => (
          <CallBlock
            key={index}
            color={getBallColor(call[0])}
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black' }}>
              {call.letter + call.number}
            </Typography>
          </CallBlock>
        ))}
      </AnimatePresence>
    </LastCalledNumber>
  );
};


const BingoNumbers = ({ calledNumbers = [] }) => {
  const letters = ['B', 'I', 'N', 'G', 'O'];
  const letterColors = ['#FF4B4B', '#4CAF50', '#2196F3', '#FF9800', '#9C27B0']; // Vibrant colors
  const isNumberCalled = (number) => calledNumbers.includes(number);
  
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      gap: 0.5,
      width: '100%',
      height: '100%',
      p: 2,
      backgroundColor: '#fff', // Changed to white background
      borderRadius: 2,
      boxShadow: '0 8px 32px rgba(0,0,0,0.15)',
      border: '1px solid rgba(0,0,0,0.1)'
    }}>
      {letters.map((letter, index) => (
        <Box key={letter} sx={{ 
          display: 'flex', 
          gap: 0.5,
          alignItems: 'center',
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.05)', // Lighter background for rows
          borderRadius: 1,
          p: 0.5,
          transition: 'all 0.3s ease'
        }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 'bold',
              minWidth: '40px',
              color: letterColors[index],
              fontFamily: 'ZCOOL QingKe HuangYou',
              fontSize: { xs: '2.5rem', sm: '3rem' }, // Increased font size
              textAlign: 'center',
              textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
              padding: '0 8px'
            }}
          >
            {letter}
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            gap: 0.5,
            flexGrow: 1,
            justifyContent: 'space-between',
            flexWrap: 'nowrap'
          }}>
            {[...Array(15)].map((_, i) => {
              const number = index * 15 + i + 1;
              const isCalled = isNumberCalled(number);

              return (
                <motion.div
                  key={i}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  animate={isCalled ? {
                    scale: [1, 1.2, 1],
                    rotate: [0, 360],
                    transition: {
                      duration: 0.5,
                      repeat: 0
                    }
                  } : {}}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      width: { xs: '32px', sm: '40px' }, // Increased size
                      height: { xs: '32px', sm: '40px' }, // Increased size
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: { xs: '1.4rem', sm: '1.8rem' }, // Increased font size
                      fontFamily: 'ZCOOL QingKe HuangYou',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      color: isCalled ? '#fff' : '#333', // Darker color for uncalled numbers
                      backgroundColor: isCalled ? letterColors[index] : 'rgba(0,0,0,0.05)',
                      borderRadius: '50%',
                      transition: 'all 0.3s ease',
                      border: isCalled ? `2px solid ${letterColors[index]}` : '1px solid rgba(0,0,0,0.1)',
                      boxShadow: isCalled ? `0 0 15px ${letterColors[index]}` : '0 2px 4px rgba(0,0,0,0.1)',
                      animation: isCalled ? 'pulse 2s infinite' : 'none',
                      '@keyframes pulse': {
                        '0%': {
                          boxShadow: `0 0 0 0 ${letterColors[index]}40`
                        },
                        '70%': {
                          boxShadow: `0 0 0 10px ${letterColors[index]}00`
                        },
                        '100%': {
                          boxShadow: `0 0 0 0 ${letterColors[index]}00`
                        }
                      },
                      '&:hover': {
                        backgroundColor: isCalled ? letterColors[index] : 'rgba(0,0,0,0.1)',
                        transform: 'translateY(-2px)',
                      }
                    }}
                  >
                    {number}
                  </Typography>
                </motion.div>
              );
            })}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

import {setLatestGameTime} from '../../redux/slices/jackpot/jackpotGameplaySlice';

const Jackpot = () => {

  const dispatch = useDispatch();

  const images = [img1, img2, img3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);


  const { __todayGames } = useSelector((state) => state.jackpotGame);

  const voice_status_enabled = useSelector((state) => state.companies.enable_voice_status);
  const voiceStatusRef = useRef(voice_status_enabled);  // Use ref to persist the value
  const [currentCall, setCurrentCall] = useState({ color: 'blue', letter: 'ሃገሬ', number: 'ጌምስ' });
  const [remainingCartelas, setRemainingCartelas] = useState(35);
  const [lastWinner, setLastWinner] = useState(39);
  const [runningTime, setRunningTime] = useState(0);
  const [numberOfCalls, setNumberOfCalls] = useState(null);
  const [totalWinning, setTotalWinning] = useState(0);
  const [lastFiveCalls, setLastFiveCalls] = useState([]);
  const [showCartelaSelector, setShowCartelaSelector] = useState(false);
  const [showGameListSelector, setShowGameListSelector] = useState(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const [game_boards, setGameBoards] = useState([]);
  const [game_id, setSelectedGameId] = useState(null);
  const [game_object, setSelectedGameObject] = useState(null);
  const [selected_cartela_id, setSelectedCartelaId] = useState(null);
  const [previous_cartela_id, setPreviousCartelaId] = useState(null);
  const [called_numbers, setCalledNumbers] = useState([]);
  const [showBingoResult, setShowBingoResult] = useState(false);
  const [bingoResultData, setBingoResultData] = useState(null);
  const _logged_in_user = useSelector((state) => state.auth.user);
  const [showAnimation, setShowAnimation] = useState(false);
  const [jackpotWinnerId, setJackpotWinnerId] = useState(null);
  const bingoColors = ['blue', 'green', 'red', 'yellow', 'white'];
  const showCartelaSelectorClick = () => setShowCartelaSelector(true);
  const onGameSelectorClose = () => setShowGameListSelector(false);
  const __lastPlayedJackpotGame = useSelector((state) => state.jackpotGame.__last_game_info);
  const latestGameTime = useSelector((state) => state.jackpotGame.latestGameTime);
  const [previousWinners, setPreviousWinners] = useState(null);


  const {
    __users_credit,
  } = useSelector((state)=> state.jackpotGame);

  useEffect(() => {
    if (__lastPlayedJackpotGame && __lastPlayedJackpotGame?.winners) {
      setPreviousWinners(__lastPlayedJackpotGame);
    }
  }, [__lastPlayedJackpotGame]);

  useEffect(() => {
    if (currentCall?.number) {
      const { letter } = getBingoLetterAndColor(currentCall.number);
      const letterIndex = ['B', 'I', 'N', 'G', 'O'].indexOf(letter);
      if (letterIndex !== -1) {
        // Flash the corresponding row
        const row = document.querySelectorAll('.MuiBox-root')[letterIndex + 1];
        if (row) {
          row.style.backgroundColor = 'rgba(0,0,0,0.2)'; // Darker flash for white theme
          setTimeout(() => {
            row.style.backgroundColor = 'rgba(0,0,0,0.05)'; // Return to original color
          }, 500);
        }
      }
    }
  }, [currentCall]);
  //fetch users credit
  useEffect(()=>{
    dispatch(getJackpotCreditByPhone(_logged_in_user?.phone));
  },[]);

  useEffect(() => {
    voiceStatusRef.current = voice_status_enabled;  // Keep it updated
  }, [voice_status_enabled]);
  
  function getBingoLetterAndColor(number) {
    let letter = '';
    let color = '';

    if (number >= 1 && number <= 15) {
      letter = 'B';
      color = bingoColors[0]; // Assign fixed or random color for 'B'
    } else if (number >= 16 && number <= 30) {
      letter = 'I';
      color = bingoColors[1]; // Assign fixed or random color for 'I'
    } else if (number >= 31 && number <= 45) {
      letter = 'N';
      color = bingoColors[2]; // Assign fixed or random color for 'N'
    } else if (number >= 46 && number <= 60) {
      letter = 'G';
      color = bingoColors[3]; // Assign fixed or random color for 'G'
    } else if (number >= 61 && number <= 75) {
      letter = 'O';
      color = bingoColors[4]; // Assign fixed or random color for 'O'
    }

    return { letter, color };
  }

  // useEffect to initiate socket connection
  useEffect(() => {
    initiateSocketConnection();

    // Create reusable Audio object
    let audioPlayer = new Audio();
    
    // Subscribe to the _running_bingo_data event
    const unsubscribeRunningBingoData = subscribeToEvent('_running_bingo_data', (data) => {
      const { newNumber, calledNumbers, calledCount, runningTime, totalWinning } = data;
      //console.log(data);
      if (calledNumbers && calledNumbers.length > 0) {
        // Extract the last five numbers and their corresponding letters
        const lastFiveNumbersWithLetters = calledNumbers
          .slice(-5) // Get the last 5 numbers
          .map((number) => {
            const { letter } = getBingoLetterAndColor(number);
            return { number, letter };
          });
        setCalledNumbers(calledNumbers);
        setLastFiveCalls(lastFiveNumbersWithLetters);
      }
      if (totalWinning) {
        setTotalWinning(totalWinning);
      }
      if (newNumber) {
        const { letter, color } = getBingoLetterAndColor(newNumber);

        // Update state with the new current call
        setCurrentCall({
          color, // Random or fixed color for the bingo group
          letter, // Letter based on the bingo number
          number: newNumber,
        });

        if(calledNumbers.length >= 75)
          setGameIsRunning(false);
        
        // Voice handling
        //console.log(voiceStatusRef.current);
        if (voiceStatusRef.current) {
          try {
            let voiceFile = SelectVoice(letter, newNumber); // Pass newNumber directly
            //console.log(voiceFile);
            audioPlayer.src = voiceFile;
            audioPlayer.play().catch((error) => {
              console.log('Error playing sound:', error);
            });
          } catch (error) {
            console.log('Error selecting voice file:', error);
          }
        }
      }
      setNumberOfCalls(calledCount);
      setRunningTime(runningTime);
    });
    //_bingo_game_result
    const unsubscribeBingoResult = subscribeToEvent('_bingo_game_result', (data) => {
      
      try {
        audioPlayer?.pause();
      } catch {
        console.log('error stoping voice ...');
      }
      setShowBingoResult(true);
      setBingoResultData(data);
      setGameIsRunning(false);
      
    });

    const unsubscribeLastGameCounter = subscribeToEvent('_bingo_latest_game_counter', (data) => {
      //data {time:15:00}
      //console.log(data);
      dispatch(setLatestGameTime(data));
    });

    // Cleanup socket connection on unmount
    return () => {
      //disconnectSocket();
      unsubscribeRunningBingoData();
      unsubscribeBingoResult();
      unsubscribeLastGameCounter();
      audioPlayer.pause();
      audioPlayer = null;
    };
  }, []); // Empty dependency array to run only once on mount
const [gameIsRunning,setGameIsRunning] = useState(false);
  useEffect(() => {
    // Create reusable Audio object
    let audioPlayer = new Audio();
    //this will count down timer before game
    const unsubscribeGameStartingTimer = subscribeToEvent('_bingo_game_starting_timer', (sec) => {
      console.log(sec);
      setCurrentCall({ color: 'red', letter: sec, number: sec });
      
      if(sec == 10){
        setGameIsRunning(true);
      }
      if (sec == 3 && voiceStatusRef.current) {
        try {
          
          audioPlayer.src = readyPlay;
          audioPlayer.play().catch((error) => {
            console.log('Error playing ready sound:', error);
          });
        } catch (error) {
          console.log('Error selecting voice file:', error);
        }
      }

      if (sec == 2) {
        console.log('reseting states ...');
        setShowBingoResult(false);
        setBingoResultData(null);
        setCalledNumbers([]);
        setLastFiveCalls([]);
        setTotalWinning(0);
        setGameIsRunning(true);
      }
    });

    return () => {
      unsubscribeGameStartingTimer();
      audioPlayer.pause();
      audioPlayer = null;
      setGameIsRunning(false);
    };
  }, []);

  //get winners and jackpot winner
  useEffect(()=>{
    dispatch(fetchLastPlayedJackpotGame());
  },[]);

  //init socket
  useEffect(() => {
    // Subscribe to the _game_cartelas event if gameId is set
    const unsubscribeGameCartelas = subscribeToEvent('_game_cartelas', (data) => {
      const { _game_id, boards } = data;
      if (_game_id === game_id) {
        console.log(boards);
        setGameBoards(boards);
      } else {
        console.log('game id mismatch!');
      }
    });
    // Cleanup subscriptions on component unmount
    return () => {
      unsubscribeGameCartelas();
    };
  }, [game_id]); // Dependency array includes gameId

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Perform actions before the page is refreshed or closed
      console.log('Page is about to be refreshed or closed.');
      onCartelaSelect(null); // Call your function to handle unselected board IDs

      // For modern browsers, you can prevent the unload with this:
      event.preventDefault();
      event.returnValue = ''; // Needed for Chrome to show a confirmation dialog
    };

    // Add the beforeunload event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  const [jackpotWinners, setJackpotWinners] = useState([]);

  //useEffect for jackpot winner result
  /*useEffect(() => {
    // Subscribe to the _game_cartelas event if gameId is set
    const unsubscribeJackpotWinnerResult = subscribeToEvent('_bingo_jackpot_winner', (data) => {
      
      const { jackpot_winner_board_id } = data;
      //setShowAnimation(true);
      // Wait 5 seconds, then display the jackpot winner ID
     
      setGameIsRunning(false);

      setTimeout(() => {
        setJackpotWinnerId(jackpot_winner_board_id);
        setShowAnimation(false);
      }, 5000); // 5-second animation

    });
    // Cleanup subscriptions on component unmount
    return () => {
      unsubscribeJackpotWinnerResult();
    };
  },[]);*/

  useEffect(() => {
    const unsubscribeJackpotWinnerResult = subscribeToEvent('_bingo_jackpot_winner', (data) => {
      const { jackpotWinners } = data;
      setGameIsRunning(false);
      setJackpotWinners(jackpotWinners);
      //setShowAnimation(true);
    });

    return () => {
      unsubscribeJackpotWinnerResult();
    };
  }, []);

  /*const startWinnerSequence = (winnersList) => {
    setCurrentJackpotWinnerIndex(0);
    setShowAnimation(true);
  };

  useEffect(() => {
    if (jackpotWinners.length > 0 && currentJackpotWinnerIndex < jackpotWinners.length) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
        
        // Move to next winner after animation ends
        const nextTimer = setTimeout(() => {
          if (currentJackpotWinnerIndex < jackpotWinners.length - 1) {
            setCurrentJackpotWinnerIndex(prev => prev + 1);
            setShowAnimation(true);
          }
        }, 1000); // Delay between winners

        return () => {
          clearTimeout(nextTimer);
        };
      }, 5000); // Animation duration

      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentJackpotWinnerIndex, jackpotWinners]);*/

  


  useEffect(() => {
    if (!showBingoResult && jackpotWinners && jackpotWinners.length > 0) {
      // When showBingoResult is dismissed and jackpotWinnerId is available, show the jackpot dialog
      setShowAnimation(true);
    }else{
      setShowAnimation(false);
    }
  }, [showBingoResult, jackpotWinners]);


  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${mins}:${secs}`;
  };

  const onBuyCartelaClose = () => {
    setShowCartelaSelector(false);
    //set selected id to null so that the server will de-selects it
    onCartelaSelect(null);
    dispatch(reset_purchase_states());
  };

  const showGameListSelectorClick = async () => {
    async function fetchData() {
      const response = await dispatch(fetchGamesForToday());
      return response;
    }
    fetchData();
    setShowGameListSelector(true);
    setPreviousCartelaId(null);
    setSelectedCartelaId(null);
  };

  const handleGameSelect = async (game_id) => {
    setSelectedGameId(game_id);
    var selectedGame = await __todayGames.find((t) => t.gameId === game_id);
    if (selectedGame) {
      setSelectedGameObject(selectedGame);
    }
    setShowGameListSelector(false);
    setShowCartelaSelector(true);
    emitEvent('_get_game_cartelas', game_id);
  };

  const onCartelaSelect = (cartela_id) => {
    setPreviousCartelaId((prevId) => {
      // Create the cartelaObj with the current and previous IDs
      const cartelaObj = {
        game_id: game_id,
        selected_cartela_id: cartela_id, // Current selected cartela
        deselected_cartela_id: prevId, // Previous cartela from state
      };

      console.log(cartelaObj); // Log to verify you're sending the correct data
      emitEvent('_game_cartela_selected', cartelaObj); // Emit the event
      return cartela_id; // Update previous ID for next selection
    });

    // Update the selected ID
    setSelectedCartelaId(cartela_id);
  };

  const showTransactionHistoryClick = () => setShowTransactionHistory(true);
  const onTransactionHistoryClose = () => setShowTransactionHistory(false);
  const closeResultModal = () => setShowBingoResult(false);
  const closeJackpotDialog = () => setShowAnimation(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <BingoContainer>
      {showAnimation && (
        <JackpotDialog
          open={showAnimation}
          onClose={closeJackpotDialog}
          jackpotWinners={jackpotWinners} /*jackpotWinnerId={jackpotWinnerId}*/
        />
      )}

      {showBingoResult && (
        <ResultModal
          open={showBingoResult}
          onClose={closeResultModal}
          data={bingoResultData}
          calledNums={called_numbers}
        />
      )}

      {showCartelaSelector && (
        <BuyCartelaComponent
          open={showCartelaSelector}
          game={game_object}
          handleClose={onBuyCartelaClose}
          _cartelas={game_boards}
          _onCartelaSelect={onCartelaSelect}
        />
      )}
      {showGameListSelector && (
        <GameSelectorComponent
          open={showGameListSelector}
          handleClose={onGameSelectorClose}
          gameDataList={__todayGames}
          handleSelect={handleGameSelect}
        />
      )}
      {showTransactionHistory && (
        <JackpotTransactionModal
          open={showTransactionHistory}
          onClose={onTransactionHistoryClose}
          winningBalance={850}
          creditBalance={250}
          winningHistory={winningHistory}
          creditHistory={creditHistory}
        />
      )}

      <Grid
        container
        sx={{
          width: '100%',
          height: 'calc(100vh - 48px)',
          margin: 0, // Remove default margin
          '& .MuiGrid-item': {
            // Target all grid items
            padding: 0.5, // Consistent 4px padding for all items
          },
        }}
      >
        {/* winner banner section */}
        <Grid item xs={12} sx={{ maxHeight: '40px' }}>
          <WinnerBanner sx={{ height: '40px', overflow: 'hidden' }}>
            <MarqueeContent>
              {/* First set of content */}
              {previousWinners?.winners?.map((winner, index) => (
                <WinnerInfo key={index}>
                  🏆 winner from {winner?.ownerBranch} branch:
                  <span className="highlight">Board #{winner.boardId}</span>
                  won
                  <span className="amount">${winner.amount.toLocaleString()}</span>
                  <span style={{ margin: '0 1rem' }}>⭐</span>
                </WinnerInfo>
              ))}

              {previousWinners?.jackpotWinner &&
                previousWinners?.jackpotWinner?.map((data, index) => (
                  <WinnerInfo>
                    🎰 Jackpot Winner from {data?.jackpotWinnerBranch} branch:
                    <span className="highlight">Board #{data?.jackpotWinnerBoardId}</span>
                    won
                    <span className="amount">${data?.jackpotWinning.toLocaleString()}</span>
                    <span style={{ margin: '0 1rem' }}>🎯</span>
                  </WinnerInfo>
                ))}

              {/* Second set of content (identical) */}
              {previousWinners?.winners?.map((winner, index) => (
                <WinnerInfo key={`dup-${index}`}>
                  🏆 winner from {winner?.ownerBranch} branch:
                  <span className="highlight">Board #{winner.boardId}</span>
                  won
                  <span className="amount">${winner.amount.toLocaleString()}</span>
                  <span style={{ margin: '0 1rem' }}>⭐</span>
                </WinnerInfo>
              ))}

              {previousWinners?.jackpotWinner &&
                previousWinners?.jackpotWinner?.map((data, index) => (
                  <WinnerInfo>
                    🎰 Jackpot Winner from {data?.jackpotWinnerBranch} branch:
                    <span className="highlight">Board #{data?.jackpotWinnerBoardId}</span>
                    won
                    <span className="amount">${data?.jackpotWinning.toLocaleString()}</span>
                    <span style={{ margin: '0 1rem' }}>🎯</span>
                  </WinnerInfo>
                ))}
            </MarqueeContent>
          </WinnerBanner>
        </Grid>

        <Grid item xs={12} md={8} sx={{ maxHeight: 'calc(100vh - 88px)' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5, // Consistent small gap
                height: '100%',
                p: 0.5, // Consistent padding
              }}
            >
              <Box>
                <ActionButton
                  variant="contained"
                  startIcon={<Gamepad />}
                  onClick={showGameListSelectorClick}
                  color="primary"
                  sx={{ flex: isMobile ? '1' : '0 1 auto', display: gameIsRunning ? 'none' : 'flex' }}
                  disabled={!__users_credit || __users_credit.credit <= 0 || gameIsRunning}
                >
                  Select Game
                </ActionButton>
              </Box>
            </Box>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                flex: '1',
                minHeight: 0, // Important for flex container
              }}
            >
              <BingoNumbers calledNumbers={called_numbers} />
              {/* </Paper> */}
            </motion.div>
            {lastFiveCalls?.length > 0 && (
              <LastCalledNumberDisplay lastFiveCalls={lastFiveCalls} sx={{ height: '60px' }} />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                flex: 1,
                minHeight: 0, // Important for flex container
              }}
            >
              {/* Pattern Display */}
              <Paper
                elevation={3}
                sx={{
                  flex: 4,
                  bgcolor: '#f5f5f5',
                  borderRadius: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '50%',
                  p: 1,
                }}
              >
                <Grid container>
                  <PatternGrid
                    grid={
                      __todayGames
                        ?.filter((game) => !game.isDone)
                        ?.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime))?.[0]?.pattern?.grid || // Ascending order
                      []
                    }
                    editable={false}
                    size={30}
                  />
                </Grid>
              </Paper>

              {/* Banner */}
              <Paper
                elevation={3}
                sx={{
                  flex: 8,
                  borderRadius: 2,
                  overflow: 'hidden',
                  position: 'relative',
                  width: '100%',
                  height: '235px', // Changed to match pattern display height
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 0,
                }}
              >
                <img
                  src={images[currentImageIndex]}
                  alt={`Banner ${currentImageIndex + 1}`}
                  style={{
                    width: '100%',
                    height: '100%', // Changed to fill the entire Paper component
                    objectFit: 'cover',
                  }}
                />
              </Paper>
            </Box>
          </Box>
        </Grid>

        {/* right section (ball display) */}
        <Grid item xs={12} md={4} sx={{ maxHeight: 'calc(100vh - 88px)' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderRadius: 2,
              p: 2,
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            }}
          >
            {/* Winning Amount Card */}
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  backgroundColor: '#e8f5e9',
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  {/* Total Winning Card */}
                  <Box
                    sx={{
                      flex: 1,
                      p: 2,
                      bgcolor: '#4CAF50',
                      borderRadius: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                      transition: 'transform 0.2s',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                      },
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
                      Total Winning
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <PaidIcon sx={{ color: '#fff', fontSize: 24 }} />
                      <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fff' }}>
                        ${totalWinning.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Next Game Timer Card */}
                  {latestGameTime?.timeString && (
                    <Box
                      sx={{
                        flex: 1,
                        p: 2,
                        bgcolor: '#1e3c72',
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        transition: 'transform 0.2s',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                        },
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
                        Next Game In
                      </Typography>
                      <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fff' }}>
                        {latestGameTime?.timeString || '00:00'}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Paper>
            </motion.div>

            {/* Ball Display */}
            <Paper
              elevation={3}
              sx={{
                p: 3,
                borderRadius: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
              }}
            >
              <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {getBallDisplay(currentCall)}
              </Box>
            </Paper>

            {/* Stats Cards */}
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 2,
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
                }}
              >
                <AvTimerIcon sx={{ fontSize: 30, color: '#1e3c72' }} />
                <Typography variant="h6" sx={{ color: '#1e3c72', fontWeight: 500 }}>
                  {formatTime(runningTime)}
                </Typography>
                <Typography variant="body2" sx={{ color: '#666' }}>
                  Running Time
                </Typography>
              </Paper>

              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
                }}
              >
                <NumbersIcon sx={{ fontSize: 30, color: '#1e3c72' }} />
                <Typography variant="h6" sx={{ color: '#1e3c72', fontWeight: 500 }}>
                  {numberOfCalls || 0}
                </Typography>
                <Typography variant="body2" sx={{ color: '#666' }}>
                  Total Calls
                </Typography>
              </Paper>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </BingoContainer>
  );
};

export default Jackpot;




