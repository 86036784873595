// services/jackpotCreditDistributionService.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getToken = () => {
  const token = localStorage.getItem('bingo_access_token');
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

// Service functions for each API call
const createDistribution = async (creditData) => {
  try {
    const response = await axiosInstance.post('/jackpot/credit-distribution/', creditData, getToken());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

const createDistributionBulk = async (bulkData) => {
  try {
    const response = await axiosInstance.post('/jackpot/credit-distribution/bulk', bulkData, getToken());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

const getDistributionById = async (id) => {
  try {
    const response = await axiosInstance.get(`/jackpot/credit-distribution/${id}`, getToken());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

const updateDistribution = async (id, creditData) => {
  try {
    const response = await axiosInstance.put(`/jackpot/credit-distribution/${id}`, creditData, getToken());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

const deleteDistribution = async (id) => {
  try {
    const response = await axiosInstance.delete(`/jackpot/credit-distribution/${id}`, getToken());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

const transferFunds = async (transferData) => {
  try {
    
    //const response = await axiosInstance.get(`/jackpot/credit-distribution/transfer/${id}`, getToken());
    const response = await axiosInstance.post(`/jackpot/credit-distribution/bulk-transfer`,transferData, getToken());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

export {
  createDistribution,
  createDistributionBulk,
  getDistributionById,
  updateDistribution,
  deleteDistribution,
  transferFunds
};
