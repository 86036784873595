import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Menu,
  MenuItem as MuiMenuItem,
  Modal,
  Divider

} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  AccountBalance as AccountBalanceIcon,
  CreditCard as CreditCardIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { getCreditDetailForUser } from 'src/redux/slices/jackpot/jackpotCreditSlice';
import Iconify from 'src/components/iconify';
import { useEffect } from 'react';

import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { transferFundsAsync } from 'src/redux/slices/jackpot/jackpotCreditDistributionSlice';

const JackpotTransactionDialog = ({ open, onClose, winningBalance,depositedBalance, creditBalance, winningHistory, creditHistory }) => {
  
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredWinningHistory, setFilteredWinningHistory] = useState(winningHistory);
  const [summerizedFilteredWinningHistory,setSummerizedWinningHistory] = useState([]);

  const [filteredCreditHistory, setFilteredCreditHistory] = useState(creditHistory);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedTransaction,setSelectedTransaction] = useState(null);

 


  const {__credit_distribution_error,__credit_transfered_object,__credit_transfered_msg} = useSelector(
    (state) => state.jackpotCreditDistribution
  );
  const _user = useSelector((state) => state.auth.user);

  const { __credit_detail_data } = useSelector((state) => state.jackpotCredit);
  
  useEffect(() => {
    if (__credit_detail_data) {
      const winningHistory = __credit_detail_data.winningHistory || [];
      setFilteredWinningHistory(winningHistory);
      
      if(winningHistory && winningHistory.length>0){
        const groupedTrx = winningHistory.reduce(
          (acc,{_id,phone,username,game_id,amount,playerCount,date,deposited}) => {
            if(!acc[game_id]){
              acc[game_id] = {_id,phone,username,game_id,amount:0,playerCount:0,date,deposited}
            }
            acc[game_id].amount += amount;
            acc[game_id].playerCount += playerCount;
            return acc;
          }
        ,{});
        setSummerizedWinningHistory(Object.values(groupedTrx));
      }

      // Update credit history 
      setFilteredCreditHistory(__credit_detail_data.creditHistory || []);

      // Update balances
      const newWinningBalance = __credit_detail_data.winningHistory
        ? __credit_detail_data.winningHistory
            .filter((t) => !t.deposited)
            .reduce((acc, cur) => acc + cur.amount, 0)
        : 0;

      const newDepositedBalance = __credit_detail_data.winningHistory
        ? __credit_detail_data.winningHistory
            .filter((t) => t.deposited) 
            .reduce((acc, cur) => acc + cur.amount, 0)
        : 0;

      const newCreditBalance = __credit_detail_data.creditHistory
        ? __credit_detail_data.creditHistory
            .reduce((acc, cur) => acc + cur.amount, 0)
        : 0;
    }
  }, [__credit_detail_data]);



  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    //resetDateFilter();
  };

  const formatCurrency = (amount) => `$${amount? amount.toFixed(2) : 0}`;

  const formatDate = (date) => new Date(date).toLocaleString();

  const handleDateFilter = async () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    await dispatch(getCreditDetailForUser({ start: start, end: end })); 
  };


  useEffect(()=>{
    if(__credit_transfered_object)
    {
      //console.log('selected transaction',selectedTransaction);
      const updatedWinningHistory = filteredWinningHistory.map(trx => {
        if (trx._id === selectedTransaction.id) {
          return { ...trx, deposited: true };
        }
        return trx;
      });
      setFilteredWinningHistory(updatedWinningHistory);
      //selectedTransaction.deposited = true;
    }
  },[__credit_transfered_object]);
  
  const handleMenuClick= (event,trx) =>{
    setMenuAnchorEl(event.currentTarget);
    setSelectedTransaction(trx);
    
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleWinningDiposition = () =>{

    let gameId = selectedTransaction.game_id;
    if(gameId){
      let trxHistories = filteredWinningHistory?.filter(t=>t.game_id == gameId);
      if(trxHistories && trxHistories){
        let idsToDeposit = trxHistories.map(t=>t._id);
        
        dispatch(transferFundsAsync(idsToDeposit));
      }
      
    }
    //dispatch(transferFundsAsync(selectedTransaction._id));
  }

  const [showDetailDialog,setShowDetailDialog] = useState(false);
  const [detailOfSumamry,setDetailOfSummary] = useState([]);

  const handleDistributionDetail = () => {
    setShowDetailDialog(true);
    if(selectedTransaction && selectedTransaction.game_id){
      let detailData = filteredWinningHistory?.filter(t=>t.game_id == selectedTransaction.game_id);
      if(detailData && detailData.length>0)
        setDetailOfSummary(detailData);
    }
  }

  const handleDistributionDetailClose = ()=>{
    setShowDetailDialog(false);
  }

  const resetDateFilter = () => {
    setStartDate('');
    setEndDate('');
    setFilteredWinningHistory(winningHistory);
    setFilteredCreditHistory(creditHistory);
    dispatch(getCreditDetailForUser({ start: null, end: null }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth fullScreen maxWidth="lg">
      {showDetailDialog && <DetailWinningList handleCloseModal={handleDistributionDetailClose} isModalOpen={showDetailDialog} filteredDetails={detailOfSumamry} />}
      <DialogContent dividers>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={activeTab} onChange={handleTabChange} centered>
            <Tab icon={<AccountBalanceIcon />} label="Winning Credit History" />
            <Tab icon={<CreditCardIcon />} label="Credit Transaction History" />
          </Tabs>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ mr: 2 }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ mr: 2 }}
          />
          <Button
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={handleDateFilter}
            disabled={!startDate || !endDate}
          >
            Find
          </Button>
          <Tooltip title="Clear Filter">
            <IconButton onClick={resetDateFilter} color="secondary">
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {__credit_distribution_error && (
          <span style={{ fontWeight: 'bold', color: 'red' }}>{__credit_distribution_error}</span>
        )}
        {__credit_transfered_msg && (
          <span style={{ fontWeight: 'bold', color: 'green' }}>{__credit_transfered_msg}</span>
        )}
        <Box>
          {activeTab === 0 && (
            <Paper elevation={3} sx={{ mb: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  textAlign: 'center',
                  p: 2,
                  bgcolor: 'primary.main',
                  color: 'white',
                }}
              >

                <span>Winning Balance: {formatCurrency(winningBalance)}</span>
                <span>Deposited Balance: {formatCurrency(depositedBalance)}</span>

              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ bgcolor: 'secondary.light' }}>
                      <TableCell>Phone</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Game</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Your Players</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Deposited</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {summerizedFilteredWinningHistory?.map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          color: row.deposited ? 'green' : 'inherit',
                          bgcolor: row.deposited ? 'rgb(255,0,0,0.4)' : 'inherit',
                          '&:nth-of-type(odd)': !row.deposited ? { bgcolor: 'action.hover' } : {},
                        }}
                      >
                        <TableCell>{row.phone}</TableCell>
                        <TableCell>{row.username}</TableCell>
                        <TableCell>{row.game_id}</TableCell>
                        <TableCell>{formatCurrency(row.amount)}</TableCell>
                        <TableCell>{row.playerCount}</TableCell>
                        <TableCell>{formatDate(row.date)}</TableCell>
                        <TableCell>{row.deposited ? 'True' : 'False'}</TableCell>
                        {/* Action Menu */}
                        <TableCell>
                          <IconButton onClick={(event) => handleMenuClick(event, row)}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl && selectedTransaction === row)}
                            onClose={handleMenuClose}
                          >
                            <MuiMenuItem onClick={handleWinningDiposition}>
                              <Iconify icon={'ph:hand-deposit-duotone'} sx={{ mr: 2 }} />
                              Deposit
                            </MuiMenuItem>

                            <MuiMenuItem onClick={handleDistributionDetail}>
                              <Iconify icon={'majesticons:checkbox-list-detail-line'} sx={{ mr: 2 }} />
                              Detail
                            </MuiMenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}

          {activeTab === 1 && (
            <Paper elevation={3} sx={{ mb: 2 }}>
              <Typography variant="h6" sx={{ textAlign: 'center', p: 2, bgcolor: 'primary.main', color: 'white' }}>
                Credit Balance: {formatCurrency(creditBalance.credit)}
              </Typography>

              <Box sx={{ p: 0, display: 'flex', justifyContent: 'space-around' }}>
                <span style={{ color: 'green', fontWeight: 'bold' }}>
                  Total In: {formatCurrency(filteredCreditHistory?.reduce((acc, row) => 
                    _user.phone !== row.sender_phone ? acc + row.amount : acc, 0) || 0)}
                </span>
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  Total Out: {formatCurrency(filteredCreditHistory?.reduce((acc, row) =>
                    _user.phone === row.sender_phone ? acc + row.amount : acc, 0) || 0)}
                </span>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ bgcolor: 'secondary.light' }}>
                    <TableCell>Transaction Id</TableCell>
                      <TableCell>Sender</TableCell>
                      <TableCell>Receiver</TableCell>
                      <TableCell>Amount</TableCell>
                      
                      {/* <TableCell>Credit</TableCell> */}
                      <TableCell>Type</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Reason</TableCell>
                      <TableCell>Remark</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredCreditHistory?.map((row) => (
                      <TableRow key={row._id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'action.hover' } }}>
                        <TableCell>{row._id}</TableCell>
                        <TableCell>{row.sender_phone}</TableCell>
                        <TableCell>{row.receiver_phone}</TableCell>
                        <TableCell>{formatCurrency(row.amount)}</TableCell>
                        {/* <TableCell>{formatCurrency(row.currentCredit)}</TableCell> */}
                        <TableCell
                          sx={{
                            color: _user.phone === row.sender_phone ? 'red' : 'green',
                            fontWeight: 'bold', // optional, makes text more prominent
                          }}
                        >
                          {_user.phone === row.sender_phone ? 'Transfer Out' : 'Transfer In'}
                        </TableCell>
                        <TableCell>{formatDate(row.date)}</TableCell>
                        <TableCell>{row.reason}</TableCell>
                        <TableCell>{row.remark}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function DetailWinningList({ isModalOpen, handleCloseModal, filteredDetails }) {
  return (
    <Modal open={isModalOpen} onClose={handleCloseModal} fullScreen>
      <Paper
        sx={{
          width: '90%',
          maxWidth: '800px',
          margin: 'auto',
          mt: 5,
          p: 3,
          borderRadius: 2,
          boxShadow: 24,
          outline: 'none',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Transaction Details</Typography>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ my: 2 }} />
        {filteredDetails && filteredDetails.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell><strong>Transaction ID</strong></TableCell> */}
                  <TableCell><strong>Phone</strong></TableCell>
                  <TableCell><strong>Username</strong></TableCell>
                  <TableCell><strong>Game ID</strong></TableCell>
                  <TableCell><strong>Amount</strong></TableCell>
                  <TableCell><strong>Players</strong></TableCell>
                  <TableCell><strong>Branch</strong></TableCell>
                  <TableCell><strong>Date</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDetails.map(({ _id, phone, username, game_id, amount, playerCount, date,fromBranch }) => (
                  <TableRow key={_id}>
                    {/* <TableCell>{_id}</TableCell> */}
                    <TableCell>{phone}</TableCell>
                    <TableCell>{username}</TableCell>
                    <TableCell>{game_id}</TableCell>
                    <TableCell>${amount}</TableCell>
                    <TableCell>{playerCount}</TableCell>
                    <TableCell>{fromBranch}</TableCell>
                    <TableCell>{new Date(date).toLocaleDateString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" sx={{ mt: 2 }}>
            No transactions found for this Game ID.
          </Typography>
        )}
      </Paper>
    </Modal>
  );
}

export default JackpotTransactionDialog;
