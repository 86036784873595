import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

const getToken = ()=>{
    const token = localStorage.getItem('bingo_access_token');
    const config = {
        headers:{
            Authorization: `Bearer ${token}`
        }
    };
    return config;
}


const fetchAllGames = async () => {
  const response = await axiosInstance.get(`jackpot/games`);
  return response.data;
};

const fetchGameById = async (gameId) => {
  const response = await axiosInstance.get(`jackpot/game/${gameId}`);
  return response.data;
};

const fetchGamesForToday = async (start, end, isPaid) => {
  // Build the params object with only non-null values
  const params = {};
  if (start) params.start = start;
  if (end) params.end = end;
  if (isPaid !== null && isPaid !== undefined) params.isPaid = isPaid;

  // Send the request with filtered parameters
  const response = await axiosInstance.get(`jackpot/games/today`, { params });
  return response.data;
};

//this will be used to get the latest jackpot game to show the remaining time
const getLatestJackpotGame = async () => {
  try{
    
    const response = await axiosInstance.get(`jackpot/game/latest`);
    console.log(response);
    return response.data;
  }catch(error){
    console.log(error);
    return error.response?.data || { success: false, message: 'An unexpected error occurred' };
  }
};

//this will be used to get the last game info to show the last game results
const getLastGameInfo = async () => {
  const response = await axiosInstance.get(`jackpot/game/last-game-info`);
  return response.data;
};

const createOrUpdateGame = async (gameData) => {
  const response = await axiosInstance.post(`jackpot/game`, gameData);
  return response.data;
};

const cartelaPurchase = async (requestObj) => {
  try {
    // Make the request to the API
    const response = await axiosInstance.post('jackpot/game/player-board-bought', requestObj,getToken());
    return response.data;  // Return the successful response
  } catch (error) {
    // Handle error: return exactly what your backend would send
    return error.response?.data || { success: false, message: 'An unexpected error occurred' };
  }
};


export default {
  fetchAllGames,
  fetchGameById,
  fetchGamesForToday,
  createOrUpdateGame,
  cartelaPurchase,
  getLatestJackpotGame,
  getLastGameInfo
};
