// ResultModal.jsx

import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  Box,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';
import Confetti from 'react-confetti';
import {useWindowSize} from '@react-hook/window-size'

const Cell = styled(Box)(({ theme, isCalled }) => ({
  width: '50px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #ccc',
  backgroundColor: isCalled ? '#2065D1' : '#fff',
  color: isCalled ? theme.palette.common.white : '#000',
  fontWeight: 'bold',
  borderRadius: '4px',
}));

const BingoBoard = ({ boardId, boardNumbers, calledNums, pattern, branch, winning }) => {
  const grid = [
    boardNumbers.slice(0, 5),     // First column (B): takes first 5 numbers
    boardNumbers.slice(5, 10),    // Second column (I): takes next 5 numbers
    boardNumbers.slice(10, 15),   // Third column (N): takes next 5 numbers
    boardNumbers.slice(15, 20),   // Fourth column (G): takes next 5 numbers
    boardNumbers.slice(20, 25)    // Fifth column (O): takes last 5 numbers
  ];

  const isPartOfPattern = (colIndex, rowIndex) => {
    return pattern && pattern[rowIndex] && pattern[rowIndex][colIndex];
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        marginBottom: 3,
        width: '480px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {/* Bingo Board */}
      <Box>
        <Typography variant="h5" gutterBottom>
          ካርቴላ ቁጥር:
        </Typography>
        <Typography variant="h2" gutterBottom>
          {boardId}
        </Typography>
        <Grid container spacing={1} wrap="nowrap">
          {grid.map((row, rowIndex) => (
            <Grid container item direction="column" key={rowIndex} spacing={1}>
              {row.map((number, colIndex) => {
                const isCalled = calledNums.includes(number);
                const isPatternMatched = isPartOfPattern(rowIndex, colIndex) && isCalled;
                return (
                  <Grid item key={colIndex}>
                    <Cell isCalled={isPatternMatched}>{number}</Cell>
                  </Grid>
                );
              })}
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Winning Details */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: 2,
          backgroundColor: '#f9f9f9',
          minWidth: '120px',
        }}
      >
        <Typography variant="h4" color="primary">
          🏠 {branch}
        </Typography>
        <Typography variant="h3" color="secondary">
          💰 {winning} ብር
        </Typography>
        <Typography variant="h3" color="textSecondary">
          🆔 {boardId}
        </Typography>
      </Box>
    </Paper>
  );
};

// Main modal component
const ResultModal = ({ open, onClose, data, calledNums }) => {
  const { board_info, board_numbers, message, type, pattern, winning } = data;

  if (board_info?.length !== board_numbers.length) {
    console.error('Mismatch between board_info and board_numbers length');
    return null;
  }
  const [width, height] = useWindowSize();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen>
      <Confetti width={width} height={height}/>
      <DialogTitle sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}>
        {type === 'game_win' ? '🎉 እንኳን ደስ አላቹ! 🎉' : '🤝 ጨዋታው አቻ ተጠናቋል 🤝 🏁'}
        
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h5" align="center" gutterBottom>
          {message}
        </Typography>
        <Box mt={2} display="flex" flexWrap="wrap" justifyContent="center" alignItems="flex-start" gap={2}>
          {board_info?.map(({ board_id, ownerBranch }, index) => (
            <BingoBoard
              key={board_id}
              boardId={board_id}
              boardNumbers={board_numbers[index]}
              calledNums={calledNums}
              pattern={pattern.grid}
              branch={ownerBranch}
              winning={winning}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BingoBoard.propTypes = {
  boardId: PropTypes.number.isRequired,
  boardNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
  calledNums: PropTypes.arrayOf(PropTypes.number).isRequired,
};

ResultModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    board_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    board_numbers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    calledNums: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

export default ResultModal;



