import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent,DialogActions, Typography, Box,Button } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { blue, yellow } from '@mui/material/colors';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Confetti from 'react-confetti';
import {useWindowSize} from '@react-hook/window-size'
import { reelVoice } from 'src/components/bingo/chimes';

const SlotMachineReel = ({ value, spinning, position, totalReels = 5 }) => {
  const [randomValues, setRandomValues] = useState([0]);
  
  // Get the correct digit for this reel based on position from left
  const getTargetDigit = () => {
    const stringValue = String(value).padStart(totalReels, '0'); // Pad with zeros
    return parseInt(stringValue[position]);
  };



  // Determine if this reel should show a significant digit
  const isActiveReel = () => {
    const stringValue = String(value).padStart(totalReels, '0');
    return stringValue[position] !== '0';
  };

  useEffect(() => {
    if (spinning) {
      const interval = setInterval(() => {
        setRandomValues(prevValues => {
          const newRandom = Math.floor(Math.random() * 10); // 0-9
          return [...prevValues, newRandom];
        });
      }, 100);
      return () => clearInterval(interval);
    } else {
      setRandomValues([getTargetDigit()]);
    }
  }, [spinning, value, position]);

  return (
    <Box sx={{ 
      overflow: 'hidden', 
      height: 80,
      width: 80, 
      bgcolor: isActiveReel() ? blue[900] : blue[500], // Dimmer color for leading zeros
      borderRadius: 2, 
      position: 'relative',
      boxShadow: 3,
      opacity: isActiveReel() ? 1 : 0.7, // Reduce opacity for leading zeros
    }}>
      <motion.div
        style={{ display: 'flex', flexDirection: 'column' }}
        animate={spinning ? { y: ["0%", "-100%"] } : { y: "0%" }}
        transition={spinning ? { 
          duration: 5,
          ease: "linear", 
          repeat: Infinity 
        } : { 
          type: "spring", 
          stiffness: 300, 
          damping: 30 
        }}
      >
        {randomValues.map((num, index) => (
          <Box 
            key={index} 
            sx={{ 
              height: 80, 
              width: 80, 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              background: spinning ? 'inherit' : 
                (isActiveReel() ? blue[900] : blue[500]),
            }}
          >
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold', 
                color: 'white',
                textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                opacity: isActiveReel() ? 1 : 0.7,
              }}
            >
              {num}
            </Typography>
          </Box>
        ))}
      </motion.div>
    </Box>
  );
};

// Celebration Animation Component
const CelebrationAnimation = () => (
  <AnimatePresence>
    {[...Array(30)].map((_, index) => (
      <motion.div
        key={index}
        style={{
          position: 'absolute',
          top: Math.random() * 100 + '%',
          left: Math.random() * 100 + '%',
        }}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0 }}
        transition={{ duration: 0.5, delay: Math.random() * 2 }}
      >
        <EmojiEventsIcon style={{ color: yellow[500], fontSize: 30 }} />
      </motion.div>
    ))}
  </AnimatePresence>
);

// Jackpot Dialog Component

const JackpotDialog = ({ open, onClose, jackpotWinners }) => {
  const [spinning, setSpinning] = useState(true);
  const [showWinner, setShowWinner] = useState(false);
  const [showCelebration, setShowCelebration] = useState(false);
  const [currentWinnerIndex, setCurrentWinnerIndex] = useState(0);
  const totalReels = 5;

  useEffect(() => {
    if (open) {
      startAnimation();
    }
  }, [open]);

  const startAnimation = () => {
    resetAnimation();
    setSpinning(true);
    playReelVoice();

    // Stop spinning and show winner after 5 seconds
    setTimeout(() => {
      setSpinning(false);
      setShowWinner(true);
      setShowCelebration(true);
    }, 5000);
  };

  const resetAnimation = () => {
    setShowWinner(false);
    setShowCelebration(false);
    setSpinning(false);
  };

  const showNextWinner = () => {
    if (!spinning) {
      setCurrentWinnerIndex((prev) =>
        prev < jackpotWinners.length - 1 ? prev + 1 : 0
      );
      startAnimation();
    }
  };

  const showPreviousWinner = () => {
    if (!spinning) {
      setCurrentWinnerIndex((prev) =>
        prev > 0 ? prev - 1 : jackpotWinners.length - 1
      );
      startAnimation();
    }
  };

  const playReelVoice = () => {
    const audio = new Audio(reelVoice);
    audio.play();
  };


  // const startAnimation = () => {
  //   const spinTimer = setTimeout(() => {
  //     setSpinning(false);
      
  //     const winnerTimer = setTimeout(() => {
  //       setShowWinner(true);
  //       setShowCelebration(true);

  //       // Move to next winner after 8 seconds total
  //       const nextWinnerTimer = setTimeout(() => {
  //         if (currentWinnerIndex < jackpotWinners.length - 1) {
  //           setCurrentWinnerIndex(prev => prev + 1);
  //         } else {
  //           // Optional: close dialog or reset to first winner
  //           // onClose();
  //           setCurrentWinnerIndex(0);
  //         }
  //       }, 3000);

  //       return () => clearTimeout(nextWinnerTimer);
  //     }, 1000);

  //     return () => clearTimeout(winnerTimer);
  //   }, 5000);

  //   return () => clearTimeout(spinTimer);
  // };

  const getCurrentWinner = () => jackpotWinners[currentWinnerIndex];

  const getJackpotHeader = (index) => {
    const headers = ['Jackpot 1 Winner!', 'Jackpot 2 Winner!', 'Jackpot 3 Winner!'];
    return headers[index] || 'Jackpot Winner!';
  };

  const [width, height] = useWindowSize();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: 'white',
          borderRadius: 16,
          overflow: 'hidden',
        },
      }}
    >
      <Confetti width={width} height={height}/>
      <DialogContent sx={{ p: 3, height: 500, overflow: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            position: 'relative',
          }}
        >
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                color: blue[700],
                textAlign: 'center',
                zIndex: 1,
                mb: 2,
              }}
            >
              {getJackpotHeader(currentWinnerIndex)}
            </Typography>
          </motion.div>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              p: 2,
              bgcolor: blue[50],
              borderRadius: 4,
              boxShadow: 3,
              zIndex: 1,
              justifyContent: 'center',
            }}
          >
            {[...Array(totalReels)].map((_, index) => (
              <SlotMachineReel
                key={index}
                value={getCurrentWinner()?.board}
                spinning={spinning}
                position={index}
                totalReels={totalReels}
              />
            ))}
          </Box>

          {showWinner && (
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: 'spring', stiffness: 260, damping: 20 }}
            >
              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  bgcolor: blue[50],
                  borderRadius: 2,
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" sx={{ color: blue[700], mb: 1 }}>
                  አሸናፊ
                </Typography>
                <Typography variant="h1" sx={{ mb: 1 }}>
                  ካርቴላ ቁጥር: {getCurrentWinner()?.board}
                </Typography>
                {/* <Typography variant="body1" sx={{ mb: 1 }}>
                  Phone: {getCurrentWinner()?.phone}
                </Typography> */}
                <Typography variant="h2" sx={{ mb: 1 }}>
                  ቅርንጫፍ: {getCurrentWinner()?.branch}
                </Typography>
                <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                  ደራሽ : ${getCurrentWinner()?.amount.toLocaleString()}
                </Typography>
              </Box>
            </motion.div>
          )}

          {jackpotWinners.length > 1 && (
            <div style={{ display: 'flex', gap: '1rem',marginTop:'1rem' }}>
              <Button onClick={showPreviousWinner} variant="contained" color="secondary">
                Show Previous Winner
              </Button>
              <Button onClick={showNextWinner} variant="contained" color="primary">
                Show Next Winner
              </Button>
            </div>
          )}

          {/* {showCelebration && <CelebrationAnimation />} */}

          {jackpotWinners.length > 1 && (
            <Typography
              variant="body2"
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 8,
                color: blue[500],
              }}
            >
              Winner {currentWinnerIndex + 1} of {jackpotWinners.length}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JackpotDialog;
