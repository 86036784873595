// slices/jackpotCreditDistributionSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createDistribution,
  createDistributionBulk,
  getDistributionById,
  updateDistribution,
  deleteDistribution,
  transferFunds
} from '../../../services/jackpot/jackpotCreditDistributionService';

// Async Thunks
export const createDistributionAsync = createAsyncThunk(
  'jackpotCreditDistribution/create',
  async (creditData, { rejectWithValue }) => {
    try {
      const data = await createDistribution(creditData);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createDistributionBulkAsync = createAsyncThunk(
  'jackpotCreditDistribution/createBulk',
  async (bulkData, { rejectWithValue }) => {
    try {
      const data = await createDistributionBulk(bulkData);
      return data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const getDistributionByIdAsync = createAsyncThunk(
  'jackpotCreditDistribution/getById',
  async (id, { rejectWithValue }) => {
    try {
      const data = await getDistributionById(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDistributionByPhoneAsync = createAsyncThunk(
    'jackpotCreditDistribution/getByPhone',
    async (id, { rejectWithValue }) => {
      try {
        const data = await getDistributionById(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

export const transferFundsAsync = createAsyncThunk(
  'jackpotCreditDistribution/transferFunds',
  async (transferData, { rejectWithValue }) => {
    try {
      const data = await transferFunds(transferData);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Slice
const jackpotCreditDistributionSlice = createSlice({
  name: 'jackpotCreditDistribution',
  initialState: {
    __credit_distribution_data: null,
    __credit_distribution_error: null,
    __credit_distribution_status: null,
    __credit_transfered_object:null,
    __credit_transfered_msg:null,
  },
  reducers: {
    reset_distribution_states(state,action){
      state.__credit_distribution_data =null;
      state.__credit_distribution_error= null;
      state.__credit_distribution_status= null;
    },
    reset_transfer_states(state,action){
      state.__credit_transfered_object = null;
      state.__credit_distribution_error = null;
      state.__credit_transfered_msg = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDistributionBulkAsync.fulfilled, (state, action) => {
        state.__credit_distribution_status = 'succeeded';
        state.__credit_distribution_data = action.payload;
        state.__credit_distribution_error = null;
      })
      .addCase(createDistributionBulkAsync.rejected, (state, action) => {
        state.__credit_distribution_status = null;
        state.__credit_distribution_error = action.payload.message;
      })
      .addCase(createDistributionBulkAsync.pending, (state, action) => {
        state.__credit_distribution_status = 'saving ...';
        state.__credit_distribution_error = null;
      })
      .addCase(getDistributionByPhoneAsync.fulfilled, (state, action) => {
        state.__credit_distribution_status = 'succeeded';
        state.__credit_distribution_data = action.payload;
        state.__credit_distribution_error = null;
      })
      .addCase(getDistributionByPhoneAsync.rejected, (state, action) => {
        state.__credit_distribution_status = null;
        state.__credit_distribution_error = action.payload;
      })
      .addCase(getDistributionByPhoneAsync.pending, (state, action) => {
        state.__credit_distribution_status = 'getting data ...';
        state.__credit_distribution_error = null;
      })
      .addCase(transferFundsAsync.fulfilled, (state, action) => {
        state.__credit_transfered_object = action.payload.data;
        state.__credit_distribution_error = null;
        state.__credit_transfered_msg = 'amount transfered to credit!';
      })
      .addCase(transferFundsAsync.rejected, (state, action) => {
        state.__credit_distribution_error = action.payload.message;
        state.__credit_transfered_msg = null;
      });
  }
});

export const {reset_distribution_states,reset_transfer_states} = jackpotCreditDistributionSlice.actions;
export default jackpotCreditDistributionSlice.reducer;
