import axios from 'axios'

// const API_URL = 'http://localhost:5000/api'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });


const getToken = ()=>{
    const token = localStorage.getItem('bingo_access_token');
    const config = {
        headers:{
            Authorization: `Bearer ${token}`
        }
    };
    return config;
}


const create_company = async (company) =>{
    try {
        const data = await axiosInstance.post('/company',company,getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        throw error.response.data;
    }
}


const get_all_companies = async()=>{
    try {
        const data = await axiosInstance.get('/company',getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        throw error.response.data;
    }
}

const update_company_state = async(comp_info) =>{
    try {
        const update = await axiosInstance.put('/company/updateStatus',comp_info,getToken());
        return update;
    } catch (error) {
        checkStatusAndRefreshPage(error);
    }
}

const delete_company_info = async(comp_info) =>{
    try {
        const update = await axiosInstance.put('/company/delete',comp_info,getToken());
        return update;
    } catch (error) {
        checkStatusAndRefreshPage(error);
    }
}

const getDistinctBranchNames = async () => {
    try {
        const data = await axiosInstance.get('/company/branchNames',getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
    }
}


const checkStatusAndRefreshPage = async(error)=>{
    try {
        if(error.response.status === 401){
            window.location.reload();
            localStorage.removeItem('bingo_user_info');
            localStorage.removeItem('bingo_access_token');
        }
    } catch (error) {
        
    }
}


const companyService = {
    create_company,get_all_companies,update_company_state,delete_company_info,getDistinctBranchNames
};

export default companyService;