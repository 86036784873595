import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

const getToken = ()=>{
    const token = localStorage.getItem('bingo_access_token');
    const config = {
        headers:{
            Authorization: `Bearer ${token}`
        }
    };
    return config;
}


export const getJackpotDashboardData = async (start, end) => {
  try {
    if (!start && !end) {
      const now = new Date();
      const today = new Date(now.setDate(now.getDate() + 1)).toISOString();
      const thisWeek = new Date(now.setDate(now.getDate() - 7)).toISOString();

      start = thisWeek;
      end = today;
    }
    const response = await axiosInstance.get(`/jackpot/dashboard/${start}/${end}`, getToken());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

export const fetchSummerizedDashboardData = async () => {
  try {
   
    const response = await axiosInstance.get('/jackpot/dashboard/summary', getToken());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

  
  