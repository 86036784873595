import { Dialog, DialogContent } from '@mui/material';
import CreditTransferDetailCard from '../modals/CreditTransferDetailCard';

const CreditSendConfirmationModal = ({
  open,
  onClose,
  transferDetail,
  transferDetailError,
  credit,
  onConfirm,
  currency_formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ETB',
  })
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          minWidth: { xs: '90%', sm: '500px' },
          maxWidth: '600px'
        }
      }}
    >
      <DialogContent sx={{ p: 3 }}>
        <CreditTransferDetailCard
          transferDetail={transferDetail}
          transferDetailError={transferDetailError}
          credit={credit}
          currency_formatter={currency_formatter}
          onConfirm={onConfirm}
          onCancel={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreditSendConfirmationModal;