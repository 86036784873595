// src/store/jackpotUserSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createJackpotUser,
  getJackpotUsers,
  getJackpotUserById,
  getJackpotUserByPhone,
  getJackpotUserByRole,
  updateJackpotUser,
  deleteJackpotUser,
  importUserForJackpot,
} from '../../../services/jackpot/jackpotUserService';

// Thunks for asynchronous operations
export const fetchJackpotUsers = createAsyncThunk('jackpotUser/fetchAll', async (_, { rejectWithValue }) => {
  try {
    const data = await getJackpotUsers();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchJackpotUserById = createAsyncThunk('jackpotUser/fetchById', async (userId, { rejectWithValue }) => {
  try {
    const data = await getJackpotUserById(userId);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchJackpotUserByPhone = createAsyncThunk(
  'jackpotUser/fetchByPhone',
  async (phone, { rejectWithValue }) => {
    try {
      const data = await getJackpotUserByPhone(phone);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchJackpotUserByRole = createAsyncThunk('jackpotUser/fetchByRole', async (role, { rejectWithValue }) => {
  try {
    const data = await getJackpotUserByRole(role);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const _createJackpotUser = createAsyncThunk('jackpotUser/create', async (userData, { rejectWithValue }) => {
  try {
    const data = await createJackpotUser(userData);
    return data;
  } catch (error) {
    
    return rejectWithValue(error.error);
  }
});

export const getUserForJackpot = createAsyncThunk(
  'jackpotUser/importUserForJackpot',
  async (phone, { rejectWithValue }) => {
    try {
      const data = await importUserForJackpot(phone);
      
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateUser = createAsyncThunk('jackpotUser/update', async ({ userId, userData }, { rejectWithValue }) => {
  try {
    const data = await updateJackpotUser(userId, userData);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const deleteUser = createAsyncThunk('jackpotUser/delete', async (userId, { rejectWithValue }) => {
  try {
    const data = await deleteJackpotUser(userId);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Slice
const jackpotUserSlice = createSlice({
  name: 'jackpotUser',
  initialState: {
    __users: [],
    __user: null,
    __importedUser: null,
    __status: 'idle',
    __error: null,
    __getUserLoading:null,
    __getUserMessage:null,

    //user saving/updating states
    __userSaved:null,
    __userSavedMessage:null,

    //loading users
    __loadingUsers:null,
    __usersLoaded:null,
    __loadingErrorMessage:null,
  },
  reducers: {
    clearSelectedUser: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    // Fetch all users
    builder
      .addCase(fetchJackpotUsers.pending, (state) => {
        state.__loadingUsers = true;
        state.__loadingMessage = 'loading users ...';
      })
      .addCase(fetchJackpotUsers.fulfilled, (state, action) => {
        state.__loadingUsers = null;
        state.__users = action.payload;
      })
      .addCase(fetchJackpotUsers.rejected, (state, action) => {
        state.__loadingUsers = true;
        state.__loadingMessage = action.payload;
        
      });

    // Fetch all users by role
    builder
      .addCase(fetchJackpotUserByRole.pending, (state) => {
        state.__status = 'loading';
      })
      .addCase(fetchJackpotUserByRole.fulfilled, (state, action) => {
        state.__status = 'succeeded';
        state.__users = action.payload;
      })
      .addCase(fetchJackpotUserByRole.rejected, (state, action) => {
        state.__status = 'failed';
        state.__error = action.payload;
      });

    // Fetch user by ID
    builder
      .addCase(fetchJackpotUserById.pending, (state) => {
        state.__status = 'loading';
      })
      .addCase(fetchJackpotUserById.fulfilled, (state, action) => {
        state.__status = 'succeeded';
        state.__user = action.payload;
      })
      .addCase(fetchJackpotUserById.rejected, (state, action) => {
        state.__status = 'failed';
        state.__error = action.payload;
      });

    // Fetch user by phone
    builder
      .addCase(fetchJackpotUserByPhone.pending, (state) => {
        state.__status = 'loading';
      })
      .addCase(fetchJackpotUserByPhone.fulfilled, (state, action) => {
        state.__status = 'succeeded';
        state.__user = action.payload;
      })
      .addCase(fetchJackpotUserByPhone.rejected, (state, action) => {
        state.__status = 'failed';
        state.__error = action.payload;
      });

    // import user for jackpot
    builder
      .addCase(getUserForJackpot.pending, (state) => {
        state.__getUserLoading = true;
        state.__getUserMessage = 'getting user ...';
      })
      .addCase(getUserForJackpot.fulfilled, (state, action) => {
        state.__getUserLoading = null;
        state.__getUserMessage = null;
        state.__importedUser = action.payload;
      })
      .addCase(getUserForJackpot.rejected, (state, action) => {
        state.__getUserLoading = null;
        //console.log(action.payload);
        state.__getUserMessage = action.payload;
        state.__error = action.payload;
        state.__importedUser = null;
      });

    // Create user
    builder
      .addCase(_createJackpotUser.fulfilled, (state, action) => {
        // Find and update existing user if it exists
        const existingUserIndex = state.__users.findIndex(user => user.phone === action.payload.phone);
        if (existingUserIndex !== -1) {
          state.__users[existingUserIndex] = action.payload;
        } else {
          state.__users.push(action.payload);
        }
        //state.__users.push(action.payload);
        state.__userSaved=true;
        state.__userSavedMessage = 'user saved successfully!';
      })
      .addCase(_createJackpotUser.rejected, (state, action) => {
        state.__userSaved=false;
        state.__userSavedMessage = action.payload;
      })
      .addCase(_createJackpotUser.pending, (state, action) => {
        state.__error = null;
        state.__userSaved = null;
        state.__userSavedMessage = 'saving user ...';
      });

    // Update user
    builder
      .addCase(updateUser.fulfilled, (state, action) => {
        const index = state.__users.findIndex((user) => user._id === action.payload._id);
        if (index !== -1) state.__users[index] = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.__error = action.payload;
      });

    // Delete user
    builder
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = state.__users.filter((user) => user._id !== action.payload._id);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.__error = action.payload;
      });
  },
});

export const { clearSelectedUser } = jackpotUserSlice.actions;
export default jackpotUserSlice.reducer;
