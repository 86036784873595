import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

const getToken = ()=>{
    const token = localStorage.getItem('bingo_access_token');
    const config = {
        headers:{
            Authorization: `Bearer ${token}`
        }
    };
    return config;
}


const createOrUpdateCredit = async (creditData) => {
  try {
    const response = await axiosInstance.post(`jackpot/jackpot-credit`, creditData,getToken());
    return response.data;
  } catch (error) {
    console.log(error);
    throw error?.response?.data;
  }
};

const getCreditByPhone = async (phone) => {
  try {
    const response = await axiosInstance.get(`jackpot/jackpot-credit/${phone}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getCreditHistoryByPhone = async (phone) => {
  try {
    const response = await axiosInstance.get(`jackpot/jackpot-credit/history/${phone}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


const getUserCreditData = async()=>{
  try {
    const response = await axiosInstance.get(`jackpot/jackpot-user-credits`,getToken());
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

const getCreditDetailForUser = async (start, end) => {
  try {
    // Set default dates to today if not provided
    const today = new Date().toISOString().split('T')[0];
    const formattedStart = start ? new Date(start).toISOString().split('T')[0] : today;
    const formattedEnd = end ? new Date(end).toISOString().split('T')[0] : today;

    const url = `jackpot/jackpot-credit-detail/${formattedStart}/${formattedEnd}`;
    console.log(url);

    const response = await axiosInstance.get(url, getToken());
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
};

const calculateCreditDistribution = async(gameId)=>{
  try {
    const response = await axiosInstance.get(`jackpot/jackpot-credit/distribution/${gameId}`,getToken());
    return response.data;
  } catch (error) {
    console.log(error);
    throw error?.response?.data;
  }
}

const getTransactionHistoryById = async(transactionId)=>{
  try {
    const response = await axiosInstance.get(`jackpot/jackpot-credit/transaction/${transactionId}`,getToken());
    return response.data;
  } catch (error) {
    console.log(error);
    throw error?.response?.data;
  }
}

const reverseTransaction = async(transactionId)=>{
  try {
    const response = await axiosInstance.post(`jackpot/jackpot-credit/reverse/${transactionId}`,{},getToken());
    return response.data;
  } catch (error) {
    console.log(error);
    throw error?.response?.data;
  }
}

export default {
    createOrUpdateCredit,
    getCreditByPhone,
    getCreditHistoryByPhone,
    getUserCreditData,
    calculateCreditDistribution,
    getCreditDetailForUser,
    getTransactionHistoryById,
    reverseTransaction
};
