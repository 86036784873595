import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Grid
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { getTransactionHistoryById,reverseTransaction  } from '../../redux/slices/jackpot/jackpotCreditSlice';

const ReverseTransactionModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [trxId, setTrxId] = useState('');
  
  const data = useSelector(state => state.jackpotCredit);
  const [error, setError] = useState('');
  const {__transaction_history_error,__transaction_history_loading,__transaction_history_data} = useSelector(state => state.jackpotCredit);
  
  const handleGetTransaction = async () => {
    setError('');
    if (!trxId) {
      setError('Please enter a transaction ID');
      return;
    }
    try {
      await dispatch(getTransactionHistoryById(trxId));
      
    } catch (err) {
      setError(err.message);
    } finally {
    }
  };

  const handleConfirm = async () => {
    if (__transaction_history_data) {
      await dispatch(reverseTransaction(trxId));
      //onClose();
    }
  };

  const handleClose = () => {
    setTrxId('');
    setError('');
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{ 
        style: { 
          borderRadius: '12px', 
          padding: '10px'
        } 
      }}
    >
      <DialogTitle
        style={{
          backgroundColor: '#1976d2',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        Reverse Transaction
      </DialogTitle>
      
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={9}>
              <TextField
                helperText={error || __transaction_history_error}
                fullWidth
                label="Transaction ID"
                value={trxId}
                onChange={(e) => setTrxId(e.target.value)}
                error={!!error || !!__transaction_history_error}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                onClick={handleGetTransaction}
                startIcon={__transaction_history_loading ? <CircularProgress size={20} color="inherit" /> : <SearchIcon />}
                fullWidth
              >
                Get
              </Button>
            </Grid>
          </Grid>

          {__transaction_history_data && (
  <Box sx={{ mt: 3, p: 3, border: '1px solid #ddd', borderRadius: 2 }}>
    <Typography variant="h5" gutterBottom>
      Transaction Details
    </Typography>
    <Grid container spacing={2} sx={{ mt: 2 }}>
      {/* Sender Information */}
      <Grid item xs={6}>
        <Typography variant="subtitle2" color="textSecondary">
          Sender Phone
        </Typography>
        <Typography variant="body1">
          {__transaction_history_data.senderPhone}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle2" color="textSecondary">
          Sender Name
        </Typography>
        <Typography variant="body1">
          {__transaction_history_data.senderName}
        </Typography>
      </Grid>
      
      {/* Receiver Information */}
      <Grid item xs={6}>
        <Typography variant="subtitle2" color="textSecondary">
          Receiver Phone
        </Typography>
        <Typography variant="body1">
          {__transaction_history_data.receiverPhone}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle2" color="textSecondary">
          Receiver Name
        </Typography>
        <Typography variant="body1">
          {__transaction_history_data.receiverName}
        </Typography>
      </Grid>

      {/* Transaction Amount */}
      <Grid item xs={6}>
        <Typography variant="subtitle2" color="textSecondary">
          Amount
        </Typography>
        <Typography variant="body1">
          ${__transaction_history_data.amount}
        </Typography>
      </Grid>

      {/* Transaction Date */}
      <Grid item xs={6}>
        <Typography variant="subtitle2" color="textSecondary">
          Date
        </Typography>
        <Typography variant="body1">
          {new Date(__transaction_history_data.date).toLocaleString()}
        </Typography>
      </Grid>

      {/* Remark */}
      <Grid item xs={6}>
        <Typography variant="subtitle2" color="textSecondary">
          Remark
        </Typography>
        <Typography variant="body1">
          {__transaction_history_data.remark || 'N/A'}
        </Typography>
      </Grid>
    </Grid>
  </Box>
)}
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button 
          onClick={handleClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          disabled={!__transaction_history_data || __transaction_history_loading}
          startIcon={__transaction_history_loading && <CircularProgress size={20} color="inherit" />}
        >
          Confirm Reverse
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReverseTransactionModal;