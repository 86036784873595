import React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function CustomizedSnackbars({ snackOpen, snackMessage, handleSnackClose, is_saved, is_failed, is_loading }) {
    return (
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar open={snackOpen} autoHideDuration={2000} onClose={handleSnackClose}>
          <Alert
            onClose={handleSnackClose}
            severity={(is_loading && 'info') || (is_saved && 'success') || (is_failed && 'error')}
            sx={{ width: '100%' }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Stack>
    );
  }